<template>
	<AddEditForm :headerTitle="$t('pages.product.editCategory')" :backTitle="$t('menu.backToCategoryPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :onDelete="onDelete">
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingCategory != null">
				<template v-slot:body>
					<CForm>
						<CInput :label="$t('common.name')" :placeholder="$t('pages.product.enterCategoryName')"
								v-model="editingCategory.name" :is-valid="validator" required horizontal>
							<template #invalid-feedback>
								<div class="invalid-feedback">
									{{ $t('pages.product.categoryRequired') }}
								</div>
							</template>
						</CInput>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.product.categoryParent') }}</label>
							<div class="col-sm-9 input-group">
								<select class="form-control" v-model="editingCategory.parentId">
									<option selected="selected" value="0">{{ $t('pages.product.selectCategory') }}</option>
									<option v-for="(item, idx) in parentCategories" v-bind:key="item.id" v-bind:value="item.id" v-bind:selected="idx == 0">
										{{item.hierarchyName}}
									</option>
								</select>
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('pages.category.image') }}</label>
							<div class="col-sm-9 input-group">
								<div class="col-sm-9 input-group" v-if="!editingCategory.pictureModel">
									<UploadImages ref="uploadimages" :max="1" @change="handleImages"
												  :uploadMsg="$t('common.uploadImageMessage')" />
									<div class="form-actions">
										<CButton type="button" color="primary" @click="onUploadImages">{{ $t('pages.product.uploadProductImages') }}</CButton>
									</div>
								</div>
								<div class="col-sm-9 input-group" v-else>
									<img :src="editingCategory.pictureModel.imageUrl" />
									<CButton type="button" color="danger" @click="removePicture">{{ $t('common.remove') }}</CButton>
								</div>
							</div>
						</div>

						<div v-if="invalidInput" role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label"></label>
							<div class="col-sm-9 input-group">
								<div class="requiredInput">{{ errorInput }}</div>
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>

			<Accordion :headerTitle="$t('common.displayInfo')" v-if="editingCategory != null">
				<template v-slot:body>
					<CForm>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.published') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingCategory.id"
										 color="info"
										 :checked.sync="editingCategory.published"
										 size="sm" />
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.showOnHomepage') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingCategory.id"
										 color="info"
										 :checked.sync="editingCategory.showOnHomepage"
										 size="sm" />
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.includeInTopMenu') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingCategory.id"
										 color="info"
										 :checked.sync="editingCategory.includeInTopMenu"
										 size="sm" />
							</div>
						</div>

					</CForm>
				</template>
			</Accordion>

			<Confirmation ref="confirmation"></Confirmation>
		</template>
		
	</AddEditForm>
</template>

<script>
     import i18n from '@/plugins/i18n'
    import { mapGetters, mapState } from 'vuex'
    import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
	import Confirmation from '@/components/Confirmation.vue'
	import UploadImages from "vue-upload-drop-images"
	import _ from 'lodash'
    export default {
        name: 'categoryedit',	
		data() {
			return {			
				horizontal: { label:'col-3', input:'col-9' },
				invalidInput: false,
				errorInput: '',
                files: null
			}
		},	
        components: { AddEditForm, Accordion, Confirmation, UploadImages},
        computed: {
			...mapGetters('category', ['allCategories', 'editingCategory', 'hasError', 'errorMessage', 'isLoadingCategory']),
			//...mapState('category', ['categoryTree', 'isLoadingCategoryTree']),
			//parentCategories() {
			//	if (this.categoryTree && this.editingCategory && this.editingCategory.id > 0) {
			//		return this.categoryTree.filter((val, idx) => val.id != this.editingCategory.id );
			//	}
			//	return [];
			//}
			parentCategories() {
				if (this.editingCategory == null)
					return this.allCategories;
				var catId = this.editingCategory.id;
				if (this.allCategories != null) {
                    var filteredCats = _.filter(this.allCategories, function (o) { return o.id != catId; });
					return filteredCats;
				}
				return this.allCategories;
            }
        },
		methods: {
            async handleImages(files) {
                this.files = files;
            },
            validator(val) {
                return val ? val.length > 0 : false
			},
			onGoBack() {
                this.$router.push('/products/categories');	
			},
            onSave() {
                this.updateCategory(false);
            },
            onSaveContinue() {
                this.updateCategory(true);
			},
			async onDelete() {
				if (this.editingCategory && this.editingCategory.id > 0) {

                    this.$refs.confirmation.show(i18n.t('pages.product.confirmDeleteCategory'), async () => {
                        console.log('Delete category', this.editingCategory);
                        var retVal = await this.$store.dispatch("category/deleteCategory", this.editingCategory.id);
                        if (retVal == true) {
                            this.onGoBack();
                        }
					});
                }
               
			},
            async updateCategory(bContinueEdit) {
				if(!this.validator(this.editingCategory.name))
				{					
					this.invalidInput = true;
					this.errorInput = this.$t('pages.product.categoryRequired')
					return;
				}	
							
				var retVal = await this.$store.dispatch("category/saveCategory");
                if (retVal == true && !bContinueEdit) {                    
					this.$router.push('/products/categories');	
                }
			},
			onCancel() {				
				this.$router.push('/products/categories');	
			},

			async onUploadImages() {
				if (this.editingCategory == null) {
                    this.$store.dispatch('alert/error', this.$t('pages.product.validation.selectCategoryFirst'), { root: true });
                    return;
				}
				if (this.files == null || this.files.length <= 0 ) {
                    this.$store.dispatch('alert/error', this.$t('pages.product.validation.selectImage'), { root: true });
                    return;
				}

                var retVal = await this.$store.dispatch("category/saveCategoryImage", { categoryId: this.editingCategory.id, files: this.files });
			},
			async removePicture() {
				if (this.editingCategory != null) {
                    this.$refs.confirmation.show(i18n.t("pages.product.confirmDeletePicture"), async () => {
						var retVal = await this.$store.dispatch("category/deleteCategoryImage", this.editingCategory.id);
                    });
                }
            }
        },
        mounted() {            
			this.$store.dispatch("category/editCategory", this.$route.params.id);
			this.$store.dispatch("category/getAllCategories");
        },		
    }
</script>